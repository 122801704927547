import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        url: String,
        posterUrl: String,
        width: Number,
        height: Number
    }
    play() {
        this.element.innerHTML = `
            <video autoplay controls class="img-fluid" poster="${this.posterUrlValue}" 
                width="${this.widthValue}" height="${this.heightValue}">
                <source src="${this.urlValue}" type="video/mp4">
                Tut uns leid, aber Ihr Browser unterstützt keine eingebetteten Videos.
            </video>
        `;
    }
}
