const $ = require('jquery');
// this "modifies" the jquery module: adding behavior to it
// the bootstrap module doesn't export/return anything
require('bootstrap');

import './styles/app.scss';

// start the Stimulus application
import './bootstrap';

$(window).on('load', function() {
    $('.videoPreview').click(function() {
        let $video = $(this.parentNode).find('video');
        if ($video) {
            $(this).hide();
            $video.removeClass('d-none');
            //$video.play();
        } else {
            alert('Video-Element nicht auf der Seite gefunden!');
        }
    })
});
